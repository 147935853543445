import React, {FC} from 'react';
import styled from 'styled-components';
import hamburgerMenu from "../../images/home/hamburger-menu.png";
import closeHamburgerMenu from "../../images/home/active-hamb-menu.png";

const Button = styled.button``;
const Icon = styled.img`
    width: 29px;
    height: 24px;
`;

type HamburgerMenuButtonProps = {
    toggleNavigation: () => void;
    isOpen: boolean;
}
export const HamburgerMenuButton: FC<HamburgerMenuButtonProps> = ({toggleNavigation, isOpen}) => {
  return (
    <Button
      type="button"
      onClick={toggleNavigation}
    >
      {isOpen ? (
        <Icon src={hamburgerMenu} alt="hamburger menu" />
      ) : (
        <Icon
          src={closeHamburgerMenu}
          alt="close hamburger menu"
        />
      )}
    </Button>
  );
};
