import React, { useState, useEffect, useRef } from 'react';
import { MediaShowcaseSliderWrapper } from './elements';
import useContent from '../../../hooks/useContent';

import Forbes from '../../../images/testimonial-companies/forbes.svg'
import BusinessInsider from '../../../images/testimonial-companies/business-insider.svg'
import Entrepreneur from '../../../images/testimonial-companies/entrepreneur.svg'
import Filtrated from '../../../images/testimonial-companies/filtrated.svg'
import MensHealth from '../../../images/testimonial-companies/mens-health.svg'
import ForbesSelected from '../../../images/testimonial-companies/forbes-selected.svg'
import BusinessInsiderSelected from '../../../images/testimonial-companies/business-insider-selected.svg'
import EntrepreneurSelected from '../../../images/testimonial-companies/entrepreneur-selected.svg'
import FiltratedSelected from '../../../images/testimonial-companies/filtrated-selected.svg'
import MensHealthSelected from '../../../images/testimonial-companies/mens-health-selected.svg'
import { TestimonialText } from '../sections/elements';
import { VerticalSpacer } from '../../../common/elements';

export const MediaShowcaseSlider = () => {
    const { content } = useContent("home")
    const sectionKey = "companyTestimonialSection";

    const [textNav, setTextNav] = useState(null);
    const [thumbNav, setThumbNav] = useState(null);
    const [currentSlider, setCurrentSlider] = useState(2);
    const firstSlider = useRef(null);
    const secondSlider = useRef(null);

    const quoteSliderSettings = {
        asNavFor: thumbNav,
        slidesToScroll: 1,
        dots: true,
        autoplay: true,
        autoplaySpeed: 4000,
        infinite: true,
        ref: firstSlider,
        initialSlide: 2
    };

    const thumbSliderSettings = {
        asNavFor: textNav,
        ref: secondSlider,
        slidesToShow: 3,
        swipeToSlide: true,
        focusOnSelect: true,
         beforeChange: (current: any, next: any) => {
           setCurrentSlider(next);
         },
        afterChange: (current: any) => setCurrentSlider(current),
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 2
                }
            }
        ]
    };

    useEffect(() => {
        setTextNav(firstSlider.current);
        setThumbNav(secondSlider.current);
    }, [firstSlider, secondSlider]);

    return (
        <>
            <MediaShowcaseSliderWrapper {...quoteSliderSettings}>
                <div>
                    <TestimonialText>
                        {content([sectionKey, "filtrated"])}
                    </TestimonialText>
                </div>
                
                <div>
                    <TestimonialText>
                        {content([sectionKey, "mensHealth"])}
                    </TestimonialText>
                </div>
                <div>
                    <TestimonialText>
                        {content([sectionKey, "enterpreneur"])}
                    </TestimonialText>
                </div>
            </MediaShowcaseSliderWrapper>
            <VerticalSpacer extraLarge />
            <MediaShowcaseSliderWrapper {...thumbSliderSettings}>
            <div>
                    <img src={currentSlider === 0 ? FiltratedSelected : Filtrated} alt="Filtrated" width="150" height="34" />
                </div>
                                <div>
                    <img src={currentSlider === 1 ? MensHealthSelected : MensHealth} alt="Men's Health" width="150" height="34" />
                </div>
                <div>
                    <img src={currentSlider === 2 ? EntrepreneurSelected : Entrepreneur} alt="Entrepreneur" width="150" height="34" />
                </div>
               
            </MediaShowcaseSliderWrapper>
        </>
    );
}