import React from "react"
import { Container, VerticalSpacer } from "../../../common/elements"
import { MediaShowcaseSlider } from '../components'

export const CompanyTestimonials = () => {
  return (
    <Container>
      <VerticalSpacer extraExtraLarge />
      <MediaShowcaseSlider />
      <VerticalSpacer extraExtraLarge />
    </Container>
  )
}
